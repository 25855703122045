import React from "react";

const ThemeSuspense = () => {
	return (
		<div className="w-full h-screen p-6 text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-gray-900 flex justify-center items-center">
			<div className="flex flex-row justify-center items-center">
				<svg
					className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-800 dark:text-gray-100 dark:bg-gray-900"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
				>
					<circle
						className="opacity-25"
						cx="12"
						cy="12"
						r="10"
						stroke="currentColor"
						strokeWidth="4"
					></circle>
					<path
						className="opacity-75"
						fill="currentColor"
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
					></path>
				</svg>
				{/* <div className="animate-spin h-5 w-5 mr-3"></div> */}
				Loading...
			</div>
		</div>
	);
};

export default ThemeSuspense;
