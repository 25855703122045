import React, { lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "./utils/toast";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import PrivateRoute from "./components/login/PrivateRoute";

const Layout = lazy(() => import("./layout/Layout"));
const Landing = lazy(() => import("./pages/landing/home"));
const LandingDocumentation = lazy(() => import("./pages/landing/documentation"));
const Login = lazy(() => import("./pages/Login"));
const SignUp = lazy(() => import("./pages/SignUp"));
const ForgetPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const Terms = lazy(() => import("./pages/landing/terms"));
const Policy = lazy(() => import("./pages/landing/policy"));
const Refund = lazy(() => import("./pages/landing/refund"));

const App = () => {
	return (
		<>
			<ToastContainer />
			<Router>
				<AccessibleNavigationAnnouncer />
				<Switch>
					<Route exact path="/" component={Landing} />
					<Route exact path="/documentation" component={LandingDocumentation} />
					<Route exact path="/terms" component={Terms} />
					<Route exact path="/privacy" component={Policy} />
					<Route exact path="/refund" component={Refund} />
					<Route path="/login" component={Login} />
					<Route path="/signup" component={SignUp} />
					<Route path="/forgot-password" component={ForgetPassword} />
					<Route path="/reset-password" component={ResetPassword} />

					<PrivateRoute>
						<Route path="/" component={Layout} />
					</PrivateRoute>
					{/* <Redirect exact from="/" to="/" /> */}
				</Switch>
			</Router>
		</>
	);
};

export default App;
